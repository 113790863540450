@use "styles/sass/base/typography" as *;

.container-footer {
	@apply bg-neutral-low-pure-500 text-neutral-high-pure-50;

	.content-list-phone {
		@apply flex border-t;

		.wrapper-general {
			@apply flex py-0 border-t-0;
		}

		.contact {
			@apply relative flex-1 py-10 border-r border-solid border-neutral-low-400 flex flex-col px-8;

			&:first-child {
				@apply pl-0 pr-12;
			}

			&:last-child {
				@apply border-r-0;
			}

			a {
				@apply absolute top-0 left-0 right-0 bottom-0 w-full h-full;
			}
		}
	}

	.content-list-links {
		@apply border-t;

		.wrapper-general {
			@apply flex py-0 border-t-0;
		}

		.links {
			@apply flex-1 flex flex-col py-6 px-8;

			&:first-child {
				@apply pl-0 pr-12;
			}

			h3 {
				@apply mb-8;
			}

			a,
			> div {
				@apply flex items-center mb-3 cursor-pointer;

				svg {
					@apply ml-1;
				}

				&:hover {
					@apply underline;
				}
			}
		}
	}

	.content-about {
		@apply border-t;

		.wrapper-general {
			@apply flex py-12 items-center border-t-0;

			&:last-child {
				@apply pt-0;
			}
		}

		.logo {
			@apply mr-8;
		}

		.address-link {
			@apply flex flex-col;

			ul {
				@apply flex mt-2;

				li {
					@apply mr-2;

					a {
						&:hover {
							@apply underline;
						}
					}
				}
			}
		}

		.links-shared {
			@apply flex flex-1 flex-col justify-between;

			.shared {
				@apply flex gap-8;
			}
		}
	}

	.content-copyright {
		.wrapper-general {
			@apply py-4;
		}
	}

	.footer-section-title {
		font-family: Sora;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 24.59px;
	}

	.footer-section-text {
		font-family: Sora;
		font-size: 26px;
		font-style: normal;
		font-weight: 400;
		line-height: 35.52px;
	}

	.footer-section-title-big {
		font-family: Sora;
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	}
}

@media only screen and (max-width: 1023px) {
	.container-footer {
		.content-list-phone {
			.contact {
				@apply px-7;
			}
		}

		.content-list-links {
			.wrapper-general {
				@apply border-0;
			}

			.links {
				@apply px-6;
			}
		}

		.content-about {
			.logo {
				@apply mb-8;
			}

			.address-link {
				@apply mb-8;
			}
		}
	}
}

@media only screen and (max-width: 1023px) {
	.container-footer {
		.content-list-phone {
			@apply border-b-0;

			.wrapper-general {
				@apply flex-col px-0 mb-0;

				.contact {
					@apply px-6 py-8 border-r-0 border-b border-solid border-neutral-low-400;
				}
			}
		}

		.content-list-links {
			.wrapper-general {
				@apply flex-col p-6;

				.links {
					@apply px-0 py-4;
				}
			}
		}

		.content-about {
			.wrapper-general {
				@apply flex-col items-start pb-0 mb-0;
			}

			.logo {
				@apply w-auto;
			}

			.address-link {
				address {
					@apply mb-2;
				}

				ul {
					@apply flex-col;

					li {
						@apply mb-2;

						&:nth-child(2n + 2) {
							@apply hidden;
						}
					}
				}
			}

			.links-shared {
				@apply flex-col items-start flex-1 w-full;

				.shared {
					@apply justify-between w-full pt-5 pb-5 relative;

					&:before {
						content: "";
						width: 100vw;
						left: 50%;
						top: 0;
						transform: translateX(-50%);
						@apply absolute top-0 border-b border-solid border-neutral-low-400;
					}

					&:after {
						content: "";
						width: 100vw;
						left: 50%;
						bottom: 0;
						transform: translateX(-50%);
						@apply absolute top-0;
					}
				}
			}
		}

		.content-copyright {
			.wrapper-general {
				@apply mb-0;
			}
		}

		.footer-section-title {
			@include font-body-regular-small;
		}

		.footer-section-text {
			@include font-body-regular-medium;
		}
	}
}

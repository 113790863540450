@use "styles/sass/abstracts/variables" as var;

header.header {
	height: var.$header-height;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: var.$neutral-high-pure-50;
	z-index: 100;
	transition: top ease-in-out 0.7s;
	border-block-end: 1px solid;

	@apply w-screen px-0 py-4 flex justify-center border-b-neutral-high-100 md:h-24;

	@media only screen and (max-width: 1023px) {
		@apply h-16;
	}

	.wrapper-general {
		width: 100vw;
		max-width: 100vw;
		padding-inline: 0;
		@apply flex py-0 m-0 lg:px-[3rem] px-6 border-t-0 z-50 bg-neutral-high-pure-50;

		> div {
			@apply flex items-center;

			.hamburger-icon {
				@apply mr-4;
			}
		}

		.logo-wrapper {
			@apply flex;
			align-items: center;
			font-weight: var.$weight-semibold;

			img.logo {
				@apply min-w-[4rem] mr-4 max-lg:mr-8;
			}
		}

		nav {
			@apply flex items-center gap-6;

			.nav-button {
				@apply py-2 px-3;
			}

			div {
				.menu-toggle {
					@apply flex items-center gap-2;

					.arrow-icon {
						@apply transition-all ease-out;

						&.active {
							transform: rotate(-180deg);
							background-color: transparent;
						}
					}
				}

				.location-list {
					@apply absolute top-24 shadow-md;

					> a {
						@apply font-light text-neutral-low-pure-500 transition-all duration-300 ease-in-out px-8 py-4 bg-neutral-high-pure-50;
						font-size: 0.938rem;
						line-height: 1.188rem;

						&:not(:last-child) {
							@apply border-solid border-0 border-b border-neutral-high-100;
						}
					}
				}
			}
		}

		.help-wrapper {
			@apply gap-4 flex justify-end;
			align-items: center;

			.divider {
				@apply w-px h-6 border border-solid border-neutral-high-100;
			}
		}

		.container-bag {
			@apply relative ml-10 cursor-pointer hover:opacity-90;

			&:hover {
				path {
					@apply fill-neutral-high-pure-50;
				}
			}

			@media only screen and (max-width: 1023px) {
				@apply ml-5;
			}

			span {
				font-size: 10px;
				line-height: 10px;
				@apply bg-neutral-low-pure-500 font-bold text-neutral-high-pure-50 rounded-full w-4 h-4 absolute -top-1 -right-1 flex items-center justify-center;
			}
		}
	}

	a,
	span {
		font-size: 0.938rem;
		line-height: 1.188rem;
		@apply text-neutral-low-pure-500 font-light flex flex-row justify-between cursor-pointer;

		svg {
			@apply ml-1;
		}

		&:hover {
			path {
				@apply fill-neutral-low-pure-500;
			}
		}

		&:active {
			@apply text-neutral-low-pure-500;

			path {
				@apply fill-neutral-low-pure-500;
			}
		}
	}
}

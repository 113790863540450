@use "styles/sass/abstracts/variables" as var;
@use "styles/sass/base/typography" as *;

%border-bottom-active-tab {
	@apply w-full border-0 border-b-4 border-solid;
	border-color: #1d1d1d;
	position: absolute;
	content: "";
	bottom: 0;
	left: 0;
}

header.header-backoffice {
	@apply w-full fixed top-0 left-0 right-0 bg-neutral-high-pure-50;
	@apply border-b-2 border-b-neutral-high-100;

	transition: top ease-in-out 0.7s;
	z-index: 100;

	.content {
		height: var.$header-height;
		max-width: var.$maximum-width;
		@apply px-8 py-0 mr-auto ml-auto flex justify-between items-center;

		nav.tab-wrapper {
			@apply flex h-full;

			> ul {
				@apply flex h-full;

				li {
					@apply border-r border-neutral-high-pure-50 cursor-pointer hover:text-neutral-low-400;
					@apply relative h-full flex items-center justify-center px-6;

					&.active-header-tab {
						> span {
							@apply font-semibold;
						}

						&:after {
							@extend %border-bottom-active-tab;
						}
					}

					&.hidden-header-tab {
						display: none;
					}
				}
			}
		}

		.profile-wrapper {
			@apply flex justify-end items-center gap-4;

			.divider {
				@apply w-px h-6 border border-solid border-neutral-high-100;
			}
		}
	}
}
